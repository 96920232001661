<template>

  <BasePage
    padding
    title="Clienti"
    subtitle="gestione clienti"
    :breadcrumb="$route.matched">

    <template #actions>
      <q-btn
        round
        color="pink"
        icon="mdi-plus"
        @click="show( 'create' )">
        <q-tooltip>Nuovo cliente</q-tooltip>
      </q-btn>
    </template>

    <!-- detail -->
    <template v-if="detailShow">

      <BaseCard
        :header="detail.fullname"
        v-if="detailEdit">

        <CustomerForm
          class="q-pa-md"
          :errors="errors"
          v-model="editable">

          <BaseBtn
            label="Salva"
            class="q-mr-md"
            @click="save()"
            :disabled="!detailDirty" />

          <BaseBtn
            label="Annulla"
            color="red"
            @click="show()" />

        </CustomerForm>
      </BaseCard>

      <template v-else>

        <CustomerCard
          title=" "
          :header="detail.fullname"
          :customer="detail">

          <template #footer>

            <BaseBtn
              label="Modifica"
              icon="mdi-pencil"
              @click="edit()" />

            <BaseBtn
              label="Elimina"
              icon="mdi-delete-outline"
              color="negative"
              @click="destroy()" />

            <CustomerContact class="q-ml-auto" :customer="detail" />

          </template>

          <BaseModal
            confirm
            v-model="detailDestroy"
            @close="show()"
            @confirm="apiDestroy( detail.id )"
            title="Conferma richiesta"
            label="Elimina"
            ko-label="Annulla">
            Rimuovere il cliente <strong>{{ detail.firstname }} {{ detail.lastname }}</strong>?
          </BaseModal>

        </CustomerCard>

        <BaseCard
          header="Veicoli associati"
          multi-section>
          <CustomerVehicleList
            :customer="detail.id"
            @select="({id}) => $router.push( { name: 'vehicleShow', params: { id } } )"
            readonly />
        </BaseCard>

      </template>

    </template>

    <!-- table -->
    <CustomerTableList v-else
      :data="data"
      :loading="loading"
      :pagination.sync="pagination"
      :filters="filters"
      @filter="newFilters => filters = newFilters"
      @row-click="(e,row) => show( row.id )"
      @request="onRequest"
    />

  </BasePage>
</template>

<script>
import Customers from '../apis/customers.js'
import CustomerCard from '../components/customers/CustomerCard.vue'
import CustomerTableList from '../components/customers/CustomerTableList.vue'
import CustomerForm from '../components/customers/CustomerForm.vue'
import CustomerVehicleList from '../components/customers/CustomerVehicleList.vue'
import useApiCrudTable from '../hooks/apiCrudTable.js'
import { toRefs, watch } from '@vue/composition-api'
import CustomerContact from '../components/customers/CustomerContact.vue'

export default {
  name : 'Customers',

  components: {
    CustomerCard,
    CustomerForm,
    CustomerTableList,
    CustomerVehicleList,
    CustomerContact,
},

  setup(_,ctx){

    const {
      close,
      errors,
      loading,
      onRequest,
      state,
      show,
      edit,
      destroy,
      apiDestroy,
      save,
    } = useApiCrudTable({
      api: Customers,
      ctx,
      routes: {
        create: 'customerCreate',
        destroy: 'customerDestroy',
        edit: 'customerEdit',
        index: 'customers',
        show: 'customerShow',
      },
      filters: {
        sort: 'firstname',
        dir: 'asc',
        query: '',
      },
    })

    //breadcrumb name
    watch(() => ctx.root.$route.params.id, () => {
      if( ctx.root.$route.name == 'customerShow' ){
        ctx.root.$route.meta.breadcrumb.label = '...'
      }
    })
    watch(() => state.detail.fullname, value => {
      if( ctx.root.$route.name == 'customerShow' ){
        ctx.root.$route.meta.breadcrumb.label = value
      }
    })

    return {
      apiDestroy,
      close,
      destroy,
      edit,
      errors,
      loading,
      onRequest,
      save,
      show,
        ...toRefs(state),
    }
  }
}
</script>

