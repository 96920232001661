<template>

  <q-table
    class="wrapped"
    flat
    square
    binary-state-sort
    v-bind="$attrs"
    v-on="$listeners"
    :columns="columns">

    <template #top>

      <CustomerListFilters
        :value="filters"
        @input="filters => $emit( 'filter', filters )" />

    </template>

  </q-table>

</template>

<script>
import CustomerListFilters from './CustomerListFilters.vue'

export default{
  name : 'CustomerTableList',

  components: {
    CustomerListFilters,
  },

  props: {
    filters: {
      type: Object,
    },

  },

  setup(){

    const columns = [
      {
        name: 'id',
        label: 'ID',
        field: 'id',
        align: 'left',
        style: 'width:38px'
      },
      {
        name: 'firstname',
        label: 'Nome',
        field: 'firstname',
        sortable: true
      },
      {
        name: 'lastname',
        label: 'Cognome',
        field: 'lastname',
        sortable: true
      },
      {
        name: 'email',
        label: 'Email',
        field: 'email'
      },
      {
        name: 'fiscal_code',
        label: 'Codice Fiscale',
        field: 'fiscal_code'
      },
      {
        name: 'phone',
        label: 'Telefono',
        field: 'phone'
      },
    ]

    return {
      columns,
    }
  }

}
</script>
