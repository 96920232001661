<template>
  <div>
    <q-toolbar class="q-pa-none" v-if="!readonly">
      <q-toolbar-title>Seleziona il veicolo</q-toolbar-title>
      <BaseBtn icon="mdi-plus" label="Nuovo" @click="$emit('create')" />
    </q-toolbar>
    <q-list
      :bordered="!readonly"
      separator>
      <template v-for="item in data">
        <q-item clickable @click="$emit( 'select', item )" :key="`vehicle-${item.id}`">
          <q-item-section>
            <q-item-label overline>
              <q-icon name="mdi-card-text" /> {{ item.plate_number }}
            </q-item-label>
            <q-item-label>{{ item.model.brand.name }} - {{ item.model.name }}</q-item-label>
            <q-item-label caption>
              Anno : {{ item.model.year }}<br/>
              N° Telaio: {{ item.frame_number }}<br/>
              N° Serie: {{ item.serial_number }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </template>
    </q-list>
  </div>
</template>

<script>
import useApiCrud from '../../hooks/apiCrud.js'
import Vehicles from '../../apis/vehicles.js'
import { toRefs } from '@vue/composition-api'

export default{

  props: {
    customer: {
      type: Number,
      required: true,
    },

    readonly: {
      type: Boolean,
    },
  },

  setup(props,ctx){

    const {
      apiIndex,
      state,
    } = useApiCrud({
      ctx,
      api: Vehicles,
      routed: false,
    })

    //no result, emit new
    apiIndex( { owner : props.customer } ).then( response => {
      if( response.meta.total == 0 ){
        ctx.emit( 'create' )
      }
    })

    return {
      ...toRefs(state)
    }
  },
}
</script>

