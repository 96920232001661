<template>
  <div>

    <div class="row">
      <div class="col-12 col-sm-6 q-pr-sm-lg q-pb-sm">
        <BaseInput
          label="Cognome"
          error-name="lastname"
          :errors="errors"
          v-model="customer.lastname" />
      </div>

      <div class="col-12 col-sm-6 q-pb-sm">
        <BaseInput
          label="Nome"
          error-name="firstname"
          :errors="errors"
          v-model="customer.firstname" />
      </div>

      <div class="col-12 col-md-6 q-pr-md-lg q-pb-sm">
        <BaseInput
          type="email"
          label="Email"
          error-name="email"
          :errors="errors"
          v-model="customer.email" />
      </div>

      <div class="col-12 col-md-3 col-sm-6 q-pb-sm q-pr-sm-lg">
        <BaseInput
          label="Telefono principale"
          error-name="phone"
          :errors="errors"
          v-model="customer.phone" />
      </div>

      <div class="col-12 col-md-3 col-sm-6 q-pb-sm">
        <BaseInput
          label="Telefono secondario"
          error-name="phone2"
          :errors="errors"
          v-model="customer.phone2" />
      </div>

      <div class="col-12 col-sm-6 q-pr-sm-lg q-pb-sm">
        <BaseInput
          label="Codice Fiscale"
          error-name="fiscal_code"
          :errors="errors"
          v-model="customer.fiscal_code" />
      </div>

      <div class="col-12 col-sm-6 q-pb-sm">
        <BaseInput
          label="Partita IVA"
          error-name="vat_number"
          :errors="errors"
          v-model="customer.vat_number" />
      </div>

      <div class="col-12 col-sm-8 q-pr-sm-lg q-pb-sm">
        <BaseInput
          label="Indirizzo"
          error-name="route"
          :errors="errors"
          v-model="customer.address.route" />
      </div>

      <div class="col-12 col-sm-4 q-pb-sm">
        <BaseInput
          label="Cap"
          error-name="zipcode"
          :errors="errors"
          v-model="customer.address.zipcode" />
      </div>

      <div class="col-12 col-sm-8 q-pr-sm-lg q-pb-sm">
        <BaseInput
          label="Città"
          error-name="city"
          :errors="errors"
          v-model="customer.address.city" />
      </div>

      <div class="col-12 col-sm-4 q-pb-sm">
        <BaseInput
          label="Provincia"
          error-name="district"
          :errors="errors"
          v-model="customer.address.district" />
      </div>
    </div>

    <!-- default slot -->
    <slot />

  </div>
</template>

<script>
import useVModel from '../../hooks/vModel.js'

export default{
  name: 'CustomerForm',

  props: {
    value: {
      type: Object,
    },
    errors : {
      type: Object,
    },
  },

  setup(props,ctx){
    const { vModel : customer } = useVModel( props.value, ctx )

    return {
      customer,
    }
  }

}
</script>

